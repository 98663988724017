"use client";
import { searchClient, bookIndex, searchInsightsEnabled } from "./client";
import { FC, ReactNode } from "react";
import { SearchTracker } from "./components/SearchTracker";
import { InstantSearch as Context } from "react-instantsearch"; // Replace with one above at a later point where it doesn't render children twice.
import { history } from "instantsearch.js/es/lib/routers";

interface SearchContextProps {
  children: ReactNode;
}

type Refinement = Record<string, string[]>;

interface RouterState {
  [indexName: string]: Partial<{
    query: string;
    refinementList: Refinement;
  }>;
}

export const SearchContext: FC<SearchContextProps> = ({ children }) => {
  return (
    <Context
      searchClient={searchClient}
      indexName={bookIndex}
      insights={searchInsightsEnabled}
      future={{ preserveSharedStateOnUnmount: true }}
      routing={{
        /** Inspired by [docs](https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/react/#rewriting-urls-manually) */
        router: history<RouterState>({
          createURL({ routeState, location }) {
            if (!location.pathname.includes("/search"))
              return location.toString();
            const { query, refinementList } = routeState[bookIndex];
            const queryParams = new URLSearchParams();
            if (query) queryParams.set("query", query);

            if (refinementList)
              Object.entries(refinementList).forEach(([attr, values]) => {
                values.forEach((value) => {
                  queryParams.append(attr, value);
                });
              });

            const url = ["/search", queryParams.toString()]
              .filter(Boolean)
              .join("?");
            return url;
          },
          parseURL({ location }) {
            const queryParams = new URLSearchParams(location.search);
            const query = queryParams.get("query");
            const refinementList = Array.from(queryParams.entries()).reduce<
              Record<string, string[]>
            >((acc, [attr, value]) => {
              if (attr === "query") return acc;
              if (acc[attr])
                return {
                  ...acc,
                  [attr]: [...acc[attr], value],
                };
              return { ...acc, [attr]: [value] };
            }, {});
            return {
              [bookIndex]: {
                query: query ? query : undefined,
                refinementList,
              },
            };
          },
        }),
        stateMapping: {
          stateToRoute(uiState): RouterState {
            const indexUiState = uiState[bookIndex];
            return {
              [bookIndex]: {
                query: indexUiState.query,
                refinementList: indexUiState.refinementList,
              },
            };
          },
          routeToState(routeState: RouterState) {
            const { query, refinementList } = routeState[bookIndex];
            return {
              [bookIndex]: {
                query,
                refinementList,
              },
            };
          },
        },
      }}
    >
      {children}
      <SearchTracker />
    </Context>
  );
};
